/* tslint-disable */
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js'; // Run `npm install --save classlist.js`.
import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
// import 'core-js/es/global';
import 'core-js/es/index';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
// import 'core-js/es/observable';
import 'core-js/es/parse-float';
import 'core-js/es/parse-int';
import 'core-js/es/promise';
/** Evergreen browsers require these. **/
import 'core-js/es/reflect';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';
// import 'core-js/es/system';
import 'core-js/es/weak-map';
import 'core-js/es/weak-set';
/** Old safari browsers need this keyboardevent key polyfill  **/
import 'keyboardevent-key-polyfill';
/***************************************************************************************************
 * APPLICATION IMPORTS
 */
/** IE10 and IE11 requires the following to support `@angular/animation`. */
import 'web-animations-js'; // Run `npm install --save web-animations-js`.
/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

// call polyfill to do the actual polyfill
require('keyboardevent-key-polyfill').polyfill();

(window as any)['global'] = window;

/* LocalStorage/SessionStorage Polyfill to support Safari Private Browsing (Based on:  https://stackoverflow.com/questions/14555347/html5-localstorage-error-with-safari-quota-exceeded-err-dom-exception-22-an) */
class InMemoryStorage implements Storage {
    [index: number]: string;
    [key: string]: any;

    private readonly _prefix = '_';

    setItem(key: string, value: string) {
        this[this._prefix + key] = value + ''; // forces the value to a string
    }

    getItem(key: string): string {
        return this[this._prefix + key] || null;
    }

    removeItem(key: string): void {
        delete this[this._prefix + key];
    }

    clear(): void {
        for (const key in this) {
            if (this.hasOwnProperty(key)) {
                delete this[key];
            }
        }
    }

    key(index: number): string {
        return this[index];
    }

    get length(): number {
        return Object.keys(this).length;
    }
}

try {
    localStorage.setItem('__isSupported', '1');
    localStorage.removeItem('__isSupported');
    sessionStorage.setItem('__isSupported', '1');
    sessionStorage.removeItem('__isSupported');
} catch (e) {
    console.log('localStorage and sessionStorage replaced by custom memory storage');

    const inMemoryStorage = new InMemoryStorage();

    // Overwrite storage type functions to works with Safari Private mode
    Storage.prototype.setItem = (key, value) => inMemoryStorage.setItem(key, value);
    Storage.prototype.getItem = key => inMemoryStorage.getItem(key);
    Storage.prototype.removeItem = key => inMemoryStorage.removeItem(key);
    Storage.prototype.clear = () => inMemoryStorage.clear();
    Storage.prototype.key = index => inMemoryStorage.key(index);
}
